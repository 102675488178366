import { render, staticRenderFns } from "./DefaultPlaybookSelector.vue?vue&type=template&id=33d8c471&scoped=true&"
import script from "./DefaultPlaybookSelector.vue?vue&type=script&lang=js&"
export * from "./DefaultPlaybookSelector.vue?vue&type=script&lang=js&"
import style0 from "./DefaultPlaybookSelector.vue?vue&type=style&index=0&id=33d8c471&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d8c471",
  null
  
)

export default component.exports