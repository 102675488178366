<template>
  <div class="multiselect-wrapper">
    <div class="multiselect-label">{{ staticText.defaultPlaybookLabel }}</div>
    <bao-vue-multi-select
      :options="allPlaybooks"
      :value="defaultPlaybook"
      track-by="id"
      label="name"
      :allow-empty="false"
      :show-labels="false"
      :placeholder="defaultPlaybook ? null: staticText.defaultPlaybookPlaceholder"
      @input="value => $emit('select', value)"
    />
    <span
      v-if="defaultPlaybook"
      class="remove-icon"
      @click="$emit('select', null)"
      role="button"
    >
      <i class="far fa-times-circle"></i>
    </span>
  </div>
</template>

<script>
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "DefaultPlaybookSelector",
  components: {
    BaoVueMultiSelect
  },
  data () {
    return {
      staticTextDefault: {
        defaultPlaybookLabel: "Default Playbook for New Calls",
        defaultPlaybookPlaceholder: "Select default playbook"
      }
    }
  },
  props: {
    defaultPlaybook: {
      type: Object,
      required: false,
      default: null
    }
  },
  mounted () {
    this.fetchAllPlaybooks({ ordering: "name" })
  },
  computed: {
    ...mapGetters({
      allPlaybooks: "playbook/getAllPlaybooks"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    ...mapActions({
      fetchAllPlaybooks: "playbook/fetchAllPlaybooks"
    })
  }
}
</script>

<style scoped lang="scss">

.multiselect-wrapper {
  position: relative;

  .multiselect-label {
    position: absolute;
    top: 3px;
    left: 15px;
    font-size: 12px;
    color: $slate40;
    z-index: 60;
  }

  .remove-icon {
    position: absolute;
    right: 35px;
    top: 30%;
  }
}

</style>
