<template>
  <div class="container-fluid px-5 py-4">
    <div v-if="!!error">
      <b-alert
        variant="danger"
        :show="!!error"
        dismissible
        @dismissed="error = null"
      >
        {{ staticText.errorLabel }}:
        <span v-html="error.data"></span>
      </b-alert>
    </div>

    <div v-if="!!success">
      <b-alert
        variant="success"
        :show="successCountdown"
        placement="top"
        class="position-fixed fixed-top m-0 rounded-0"
        z-index="2000"
        fade
        dismissible
        @dismissed="success = null"
      >
        {{ success.message }}
      </b-alert>
    </div>

    <div class="title">
      {{ staticText.profileTitle }}
    </div>

    <div class="card-grid pt-4">
      <!--   profile information    -->
      <div class="card-item">
        <div class="mb-2 font-weight-bold">{{ staticText.profileDataLabel }}</div>
        <!-- First name-->
        <div role="group" class="mb-2 input-wrapper">
          <b-form-input
            id="firstname-202012301533"
            v-model.trim="profileData.first_name"
            :state="firstnameState"
            :placeholder="staticText.firstNamePlaceholder"
            type="text"
            aria-describedby="input-live-feedback-for-firstname"
            class="col"
            @focus="profileData.editable=true"
          >
          </b-form-input>
          <span class="input-label">{{ staticText.firstNameLabel }}</span>
          <b-form-invalid-feedback id="input-live-feedback-for-firstname">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            {{ staticText.fieldInvalidFeedback }}
          </b-form-invalid-feedback>
        </div>

        <!-- Last name -->
        <div role="group" class="mb-2 input-wrapper">
          <b-form-input
            id="lastname-202012301534"
            v-model.trim="profileData.last_name"
            :state="lastnameState"
            :placeholder="staticText.lastNamePlaceholder"
            type="text"
            aria-describedby="input-live-feedback-for-lastname"
            class="col"
            @focus="profileData.editable=true"
          ></b-form-input>
          <span class="input-label">{{ staticText.lastNameLabel }}</span>
          <b-form-invalid-feedback id="input-live-feedback-for-lastname">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            {{ staticText.fieldInvalidFeedback }}
          </b-form-invalid-feedback>
        </div>

        <div
          role="group"
          class="mb-2 input-wrapper"
          @click="handleEmailClick"
          @mouseleave="handleEmailMouseLeave"
        >
          <b-form-input
            id="email-202012301532"
            v-model.trim="profileData.email"
            type="text"
            :state="emailState"
            readonly
            aria-describedby="input-live-feedback-for-email"
            :placeholder="staticText.emailPlaceholder"
            class="col p-3"
          >
          </b-form-input>
          <span class="input-label">{{ staticText.emailLabel }}</span>
          <transition name="animate-down">
            <div
              v-if="showEditEmailInfo"
              v-html="staticText.cannotChangeEmailText"
              class="email-info"
            ></div>
          </transition>
          <b-form-invalid-feedback id="input-live-feedback-for-email">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            {{ invalidEmailMessage }}
          </b-form-invalid-feedback>
        </div>

        <div id="controls-div-202012030654"
             class="d-flex mt-4 justify-content-end"
             v-if="profileData.editable"
        >

          <b-button id="cancel-btn-202012030654"
                    class="font-size-14 px-4"
                    variant="secondary"
                    @click="cancelProfileDataEdit"
          >
            {{ staticText.cancelBtn }}
          </b-button>

          <b-button v-if="profileData.edited"
                    variant="primary"
                    class="font-size-14 px-4 ml-2"
                    :disabled="saving || !validProfileData"
                    @click="saveProfileData"
          >
            {{ staticText.saveBtn }}
          </b-button>

        </div>

      </div>

      <!-- Password change    -->
      <div class="card-item mt-3">
        <div class="text-center" v-if="!passwordData.editable">
          <div class="mb-3">
            <img src="../../../../public/img/icons/large-lock-icon.svg"/>
            <h5 class="mt-4">
              {{ staticText.changePasswordCardTitle }}
            </h5>
            {{ staticText.changePasswordCardText }}
          </div>

          <b-btn
            id="password-change-btn-202012301543"
            variant="secondary"
            @click="passwordData.editable = true"
          >
            {{ staticText.changePasswordButton }}
          </b-btn>

        </div>
        <div v-else>
          <div class="mb-2 font-weight-bold">
            {{ staticText.changePasswordModalTitle }}
          </div>
          <base-password-change
            @form-completed="userPasswordChanged"
            @form-canceled="passwordData.editable=false"
          ></base-password-change>
        </div>
      </div>

      <!--  Other settings    -->
      <div class="card-item">
        <div class="mb-2 font-weight-bold">{{ staticText.settingsLabel }}</div>

        <!--  system language select      -->
        <SystemLanguageSelector
          ref="systemLanguage"
          class="mb-2"
          @select="selectSystemLanguage"
        />

        <!--  Audio input language select      -->
        <AudioInputLanguageSelector
          v-if="canUseBaoAudio"
          ref="audioInputLanguage"
          class="mt-2"
          :pre-selected-language="preSelectedLanguage"
          @select="selectInputLanguage"
        />

        <default-playbook-selector
          class="mt-2"
          :default-playbook="selectedDefaultPlaybook"
          @select="selectDefaultPlaybook"
        />

        <div
          role="group"
          class="large-switch d-flex justify-content-between bg-white border-radius-12 p-3 mt-2"
        >
          <div class="row">
            <b-form-checkbox
              id="show-celebration-theme-202405030119"
              v-model="settingsData.animate_bao_swift"
              size="lg"
              class="ml-2"
              switch
              @change="settingsData.editable=true"
            >
              {{ staticText.animateBaoSwiftLabel }}
            </b-form-checkbox>
          </div>
          <div id="animate_bao_swift_help_icon">
            <img src="../../../../public/img/icons/help-icon.svg"/>
          </div>

          <b-tooltip
            ref="animate_bao_swift_help_icon"
            variant="secondary"
            :target="'animate_bao_swift_help_icon'"
          >
            <div v-html="staticText.animateBaoSwiftTooltip"></div>
          </b-tooltip>
        </div>

        <div
          role="group"
          class="large-switch d-flex justify-content-between bg-white border-radius-12 p-3 mt-2"
        >
          <div class="row">
            <b-form-checkbox
              id="show-celebration-theme-202302261956"
              v-model="settingsData.show_celebration_theme"
              size="lg"
              class="ml-2"
              switch
              @change="settingsData.editable=true"
            >
              {{ staticText.celebrationThemeLabel }}
            </b-form-checkbox>
          </div>
          <div id="celebration_confetti_help_icon">
            <img src="../../../../public/img/icons/help-icon.svg"/>
          </div>

          <b-tooltip
            ref="celebration_confetti_help_icon"
            variant="secondary"
            :target="'celebration_confetti_help_icon'"
          >
            <div v-html="staticText.celebrationTooltipLabel"></div>
          </b-tooltip>
        </div>

        <!-- Weekly Usage Report select -->
        <div
          role="group"
          class="large-switch d-flex justify-content-between bg-white border-radius-12 p-3 mt-2"
        >
          <div class="row">
            <b-form-checkbox
              id="weekly-report-checkbox-202012301538"
              v-model="settingsData.usage_report_active"
              size="lg"
              class="ml-2"
              switch
              @change="settingsData.editable=true"
            >
              {{ staticText.usageReportLabel }}
            </b-form-checkbox>
          </div>
          <div id="switch-help-btn-202012301538">
            <img src="../../../../public/img/icons/help-icon.svg"/>
          </div>

          <b-tooltip
            ref="switch-help-btn-202012301538"
            variant="secondary"
            :target="'switch-help-btn-202012301538'"
          >
            <div v-html="staticText.usageReportTooltip"></div>
          </b-tooltip>
        </div>

        <div id="controls-div-202012030654"
             class="d-flex mt-4 justify-content-end"
             v-if="settingsData.editable"
        >

          <b-button id="cancel-btn-202012030654"
                    class="font-size-14 px-4"
                    variant="secondary"
                    @click="cancelSettingsDataEdit"
          >
            {{ staticText.cancelBtn }}
          </b-button>

          <b-button id="reset-btn-202012021448"
                    variant="primary"
                    class="font-size-14 px-4 ml-2"
                    :disabled="saving"
                    @click="saveSettingsData"
          >
            {{ staticText.saveBtn }}
          </b-button>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { profileDetailView } from "../index"
import axios from "axios"
import { mapActions, mapGetters } from "vuex"
import BasePasswordChange from "../../base/BasePasswordChange"
import AudioInputLanguageSelector from "@/apps/user/profile/AudioInputLanguageSelector"
import SystemLanguageSelector from "@/apps/user/profile/SystemLanguageSelector"
import DefaultPlaybookSelector from "./DefaultPlaybookSelector.vue"

export default {
  name: "UserProfileView",
  components: {
    BasePasswordChange,
    AudioInputLanguageSelector,
    SystemLanguageSelector,
    DefaultPlaybookSelector
  },
  data () {
    return {
      axios,
      app: profileDetailView,
      profileData: {
        editable: false,
        edited: false,
        email: null,
        first_name: null,
        last_name: null
      },
      settingsData: {
        editable: false,
        language: null,
        show_celebration_theme: false,
        audio_input_language: null,
        usage_report_active: false,
        animate_bao_swift: false
      },
      passwordData: {
        editable: false
      },
      saving: false,
      successCountdown: 0,
      alertTime: 3,
      error: null,
      success: null,
      showEditEmailInfo: false,
      selectedDefaultPlaybook: null,
      staticTextDefault: {
        profileDataLabel: "Profile Data",
        settingsLabel: "Settings",
        profileTitle: "Profile",
        changePasswordButton: "Change",
        emailLabel: "Email",
        emailPlaceholder: "Enter your email",
        fieldInvalidFeedback: "Field cannot be empty.",
        emailInvalidFeedback: "Please fill in a valid email address.",
        firstNameLabel: "Firstname",
        firstNamePlaceholder: "Enter your firstname",
        lastNameLabel: "Lastname",
        lastNamePlaceholder: "Enter your lastname",
        saveBtn: "Save Changes",
        cancelBtn: "Cancel",
        changePasswordCardTitle: "Change Password",
        changePasswordCardText: "Change your current password",
        changePasswordModalTitle: "Change Password",
        systemLanguageLabel: "System Language",
        audioInputLanguageLabel: "Standard Language for Audio Input",
        celebrationThemeLabel: "Celebration-Theme for Calls",
        usageReportLabel: "bao Report",
        animateBaoSwiftLabel: "Flashy bao SWIFT",
        usageReportTooltip:
          "Weekly bao Report of your conversations with bao via E-Mail",
        passwordChangedMsg: "Password changed successfully.",
        userDataSavedSuccessMsg: "User data saved successfully.",
        errorLabel: "Error",
        cannotChangeEmailText: "<p>For security reasons you cannot change your email address by yourself. If you want to do so, please contact your <a href='mailto:customer@bao-solutions.com'>customer success team.</a></p>",
        celebrationTooltipLabel: "Enables confetti and motivational sayings at the end of a call, depending on the result of the call.",
        animateBaoSwiftTooltip: "bao SWIFT draws attention to itself, so that it is not so easy to forget to start a call via bao."
      }
    }
  },
  async mounted () {
    await this.fetchDefaultPlaybook()
    this.selectedDefaultPlaybook = this.defaultPlaybook
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      canUseBaoAudio: "auth/canUseBaoAudio",
      defaultPlaybook: "playbook/getDefaultPlaybook",
      preSelectedLanguage: "auth/userAudioInputLanguage"
    }),
    validProfileData () {
      return [this.emailState, this.firstnameState, this.lastnameState].every(value => value === null || value)
    },
    emailState () {
      if (!this.profileData.editable) return null
      if (this.user.email === this.profileData.email) return null
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.profileData.email).toLowerCase())
    },
    firstnameState () {
      if (!this.profileData.editable) return null
      if (this.user.first_name === this.profileData.first_name) return null
      return this.profileData.first_name !== ""
    },
    lastnameState () {
      if (!this.profileData.editable) return null
      if (this.user.last_name === this.profileData.last_name) return null
      return this.profileData.last_name !== ""
    },
    invalidEmailMessage () {
      return this.profileData.email ? this.staticText.emailInvalidFeedback : this.staticText.fieldInvalidFeedback
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  created () {
    this.setupProfileData()
    this.setupSettingsData()
  },
  watch: {
    profileData: {
      deep: true,
      handler (newVal) {
        if (newVal) {
          // checks if anything is different from initial data in profileData and sets edited flag accordingly.
          const updatedValues = []
          for (const [key, value] of Object.entries(newVal)) {
            if (this.user[key] !== undefined && this.user[key] !== value) {
              updatedValues.push(value)
            }
          }
          this.profileData.edited = updatedValues.length > 0
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateLanguage: "I18nStore/getI18nData",
      getUser: "auth/getUser",
      setDefaultPlaybook: "playbook/setDefaultPlaybook",
      fetchDefaultPlaybook: "playbook/fetchDefaultPlaybook",
      unsetDefaultPlaybook: "playbook/unsetDefaultPlaybook"
    }),
    cancelProfileDataEdit () {
      this.profileData.editable = false
      this.setupProfileData()
    },
    cancelSettingsDataEdit () {
      this.settingsData.editable = false
      this.resetSettingsData()
    },
    resetSettingsData () {
      this.$refs.systemLanguage.setUserSelectedLanguage()
      if (this.canUseBaoAudio) this.$refs.audioInputLanguage.setUserSelectedLanguage()
      this.settingsData.usage_report_active = this.user.usage_report_active
      this.settingsData.show_celebration_theme = this.user.show_celebration_theme
      this.settingsData.animate_bao_swift = this.user.animate_bao_swift
      this.selectedDefaultPlaybook = this.defaultPlaybook
    },
    selectInputLanguage (language) {
      if (language) {
        this.settingsData.audio_input_language = language.value
        this.settingsData.editable = true
      }
    },
    selectSystemLanguage (language) {
      if (language) {
        this.settingsData.language = language.value
        this.settingsData.editable = true
      }
    },
    selectDefaultPlaybook (playbook) {
      this.settingsData.editable = true
      this.selectedDefaultPlaybook = playbook
    },
    setupProfileData () {
      const { email, first_name, last_name } = this.user
      this.profileData.email = email
      this.profileData.first_name = first_name
      this.profileData.last_name = last_name
    },
    setupSettingsData () {
      const { usage_report_active, language, audio_input_language, show_celebration_theme, animate_bao_swift } = this.user
      this.settingsData.language = language
      this.settingsData.audio_input_language = audio_input_language
      this.settingsData.usage_report_active = usage_report_active
      this.settingsData.show_celebration_theme = show_celebration_theme
      this.settingsData.animate_bao_swift = animate_bao_swift
    },
    userPasswordChanged () {
      this.successCountdown = this.alertTime
      this.success = {
        message: this.staticText.passwordChangedMsg
      }
      this.passwordData.editable = false
    },
    switchUsageReport () {
      const url = "/api/users/switch_usage_report"
      this.axios
        .get(url, { params: { activate: this.user.usage_report_active } })
        .catch(error => {
          this.error = error.response
        })
    },
    async updateAnimateBaoSwiftFlag () {
      const url = "/api/users/update_animate_bao_swift"
      try {
        const resp = await this.axios.put(url, { animate_bao_swift: this.settingsData.animate_bao_swift })
        this.settingsData.animate_bao_swift = resp.data.animate_bao_swift
      } catch (error) {
        this.error = error.response
      }
    },
    saveProfileData () {
      const { email, first_name, last_name } = this.profileData
      this.makeUpdateRequest({ email, first_name, last_name }, this.profileData)
    },
    updateLanguageOptions () {
      this.$refs.systemLanguage.updateTranslation()
      if (this.canUseBaoAudio) this.$refs.audioInputLanguage.updateTranslation()
    },
    translateUI () {
      this.updateLanguage()
      this.updateLanguageOptions()
    },
    saveSettingsData () {
      this.switchUsageReport()
      // call api to update animate bao swift if there is any change
      if (this.user.animate_bao_swift !== this.settingsData.animate_bao_swift) {
        this.updateAnimateBaoSwiftFlag()
      }
      const { language, audio_input_language, show_celebration_theme } = this.settingsData
      const data = { email: this.profileData.email, language, audio_input_language, show_celebration_theme } // email is mandatory field for serializer
      this.makeUpdateRequest(data, this.settingsData).then(() => {
        this.translateUI()
      })
      if (this.selectedDefaultPlaybook) this.setDefaultPlaybook(this.selectedDefaultPlaybook)
      else this.unsetDefaultPlaybook()
    },
    async makeUpdateRequest (dataToSave, context) {
      this.saving = true
      const userUrl = "/api/users/update_profile"

      await this.axios.put(userUrl, dataToSave).then(
        () => {
          this.getUser().then(
            () => {
              this.success = {
                message: this.staticText.userDataSavedSuccessMsg
              }
              this.successCountdown = this.alertTime
              this.saving = false
              context.editable = false
            },
            error => {
              this.error = error.response
              this.saving = false
            }
          )
        },
        error => {
          this.error = error.response
          this.saving = false
        }
      )
    },
    handleEmailClick () {
      this.showEditEmailInfo = true
    },
    handleEmailMouseLeave () {
      if (this.showEditEmailInfo) this.showEditEmailInfo = false
    }
  }
}
</script>

<style scoped lang="scss">

.card-grid {
  column-count: 2;

  .card-item {
    display: inline-block;
    width: 100%;
    background-color: $white80;
    border-radius: 20px;
    padding: 32px;
    min-height: 284px;
  }

}

.input-wrapper {
  position: relative;
  .email-info {
    position: absolute;
    top: 45px;
    right: 5px;
    width: 207px;
    background-color: $white;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05);
    :deep(p) {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
      color: $slate80;
    }
    :deep(a) {
      text-decoration: underline;
      color: #C5805D;
    }
  }
  .form-control:read-only {
    cursor: default;
    box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
    border: 1px solid #e9ecef !important;
  }
}
</style>
