<template>
  <div class="multiselect-wrapper">
    <div class="multiselect-label">{{ staticText.systemLanguageLabel }}</div>
    <bao-vue-multi-select
      :options="languageOptions"
      v-model="selectedLanguage"
      track-by="value"
      label="label"
      :showLabels="false"
      :searchable="false"
      :allow-empty="false"
      :placeholder="selectedLanguage ? null: staticText.selectLanguagePlaceholder"
      @input="$emit('select', selectedLanguage)"
    />
  </div>
</template>

<script>
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SystemLanguageSelector",
  components: {
    BaoVueMultiSelect
  },
  data () {
    return {
      languages: ["en", "de"],
      selectedLanguage: null,
      staticTextDefault: {
        en: "English",
        de: "German",
        systemLanguageLabel: "System Language",
        selectLanguagePlaceholder: "Select system language"
      }
    }
  },
  mounted () {
    this.setUserSelectedLanguage()
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    languageOptions () {
      return this.languages.map(language => {
        return {
          label: this.staticText[language], value: language
        }
      })
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser"
    }),
    updateTranslation () {
      this.getUser().then(() => {
        this.setUserSelectedLanguage()
      })
    },
    setUserSelectedLanguage () {
      if (this.user.language) {
        this.selectedLanguage = {
          value: this.user.language,
          label: this.staticText[this.user.language]
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.multiselect-wrapper {
  position: relative;
  z-index: 70;

  .multiselect-label {
    position: absolute;
    top: 3px;
    left: 15px;
    font-size: 12px;
    color: $slate40;
    z-index: 60;
  }
}

</style>
